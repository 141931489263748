import { Tab, TabGroup, TabList, TabPanel, TabPanels, Field, Label, Switch } from "@headlessui/react";
import { AtSymbolIcon, CodeBracketIcon, LinkIcon } from "@heroicons/react/20/solid";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { CompanyAdminUser } from "../../types";
import MultiSelectField from "../../components/MultiSelectField";
import { AuthContext } from "../../context/authContext";
import CollapsableContainer from "../../components/CollapsableContainer";
import Button from "../../components/Button";
import { PaperAirplaneIcon, PaperClipIcon, PlusIcon } from "@heroicons/react/24/outline";

export default function SendEmail() {
  const { getApiData, postApiData } = useApi();
  const auth = useContext(AuthContext);

  const [sendTo, setSendTo] = useState<"" | "all" | "companies" | "users">("");
  const [selectedCompanyNames, setSelectedCompanyNames] = useState<string[]>([]);
  const [selectedAdminEmails, setSelectedAdminEmails] = useState<string[]>([]);
  const [emailsToSend, setEmailsToSend] = useState<string[]>([]);
  const [subject, setSubject] = useState<string>("");
  const [body, setBody] = useState<string>("");
  const [attachments, setAttachments] = useState<File[]>([]);
  const [sendAsAdmin, setSendAsAdmin] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const htmlBody = `
    ${body.replaceAll("\n", "<br/>")}
    <br/><br/>
    ${
      !sendAsAdmin
        ? `
      <font size="4" color="#0b5394">${auth.claims["given_name"]} ${auth.claims["family_name"]}</font>
          <br/>
    ${auth.claims["custom:job_title"] ? `<b>${auth.claims["custom:job_title"]}</b><br/>` : ""}
    <a href="mailto:${auth.claims.email}" target="_blank">${auth.claims.email}</a>
    <br/>
      `
        : ""
    }

    <img width="200" height="29" style="margin-top: 8px" src="https://ci3.googleusercontent.com/mail-sig/AIorK4zjIYBpwS4PEF3MVYSX7p-irPeDRnZ1uqosucnECGiK-oBfjL5oYeBInceGjrmg5TQgRkDuceUTHbQt" />
    <br/>
    ${sendAsAdmin ? `<p style="font-size: 12px;">No longer wish to recieve these emails? <a href="https://forms.gle/KWu1cy2os4rnEkWJ8" style="text-decoration: underline">Click here to unsubscribe</a></p>` : ``}
  `;

  const { data: companyAdminUsers } = useQuery<CompanyAdminUser[]>({
    queryKey: ["companyAdminUsers"],
    queryFn: () => getApiData(`/strata/admins`),
    initialData: [],
  });

  const confirmedAdminUsers = companyAdminUsers.filter((cau) => cau.UserStatus === "CONFIRMED");

  const { data: companies } = useQuery<Company[]>({
    queryKey: ["companies"],
    queryFn: () => getApiData(`/strata/companies`),
    initialData: [],
  });

  async function sendEmail() {
    if (!subject || !body) {
      setError("Subject and body are required");
      return;
    }
    setSending(true);
    const formData = new FormData();

    // Append each recipient in the "to" array
    emailsToSend.forEach((email) => {
      formData.append("to[]", email);
    });

    formData.append("from", sendAsAdmin ? `Strata Intel<${ADMIN_EMAIL}>` : auth.claims.email);
    formData.append("subject", subject);
    formData.append("htmlBody", htmlBody);
    formData.append("textBody", body);

    // Append each file to the FormData object
    attachments.forEach((attachment) => {
      formData.append("files", attachment); // "files" is the key name for the uploaded files
    });

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "/strata/admins/send-email", {
        method: "POST",
        headers: {
          Authorization: auth.sessionInfo?.idToken ?? "",
        },
        body: formData,
      });

      if (response.status === 401) {
        const body = await response.json();
        if (body.name === "TokenExpiredError") {
          alert("Your session has expired, please copy the email and reload the page, then try sending again");
        }
      } else if (response.status === 200) {
        const body = await response.json();
        console.log(body);

        if (body.errors.length > 0) {
          alert(`There were errors sending some or all emails:\n
            ${body.errors.map((error: { address: string; error: string }) => `${error.address}: ${error.error}\n`)}`);
          setSending(false);
          return;
        }
        alert(`Emails sent successfully!`);
        window.location.reload();
      } else {
        console.error(response);
        alert("An error occurred sending email");
      }
    } catch (err) {
      console.error(err);
      alert("An error occurred sending email, this could be due to the attachment size being too large.");
    }
    setSending(false);
  }

  function addAll() {
    if (sendTo === "companies") {
      setSelectedCompanyNames(companies.map((c) => c.company_name));
    }
    if (sendTo === "users") {
      setSelectedAdminEmails(confirmedAdminUsers.map((cau) => cau.Attributes?.find((att) => att.Name === "email")?.Value ?? ""));
    }
  }

  useEffect(() => {
    if (sendTo === "all") {
      setEmailsToSend(confirmedAdminUsers.map((cau) => cau.Attributes?.find((att) => att.Name === "email")?.Value ?? ""));
    }
    setSelectedCompanyNames([]);
    setSelectedAdminEmails([]);
  }, [sendTo, companyAdminUsers, companies]); // clear selected values when changing send to

  useEffect(() => {
    setEmailsToSend([]);
    if (sendTo === "all") {
      setEmailsToSend(confirmedAdminUsers.map((cau) => cau.Attributes?.find((att) => att.Name === "email")?.Value ?? ""));
    }
    if (sendTo === "companies") {
      const emailsForSelectedCompanies = [];
      for (const companyName of selectedCompanyNames) {
        const company = companies.find((c) => c.company_name === companyName);
        emailsForSelectedCompanies.push(
          ...confirmedAdminUsers
            .filter((cau) => cau.companyIds.includes(company!.company_id))
            .map((cau) => cau.Attributes?.find((att) => att.Name === "email")?.Value ?? "")
        );
      }
      setEmailsToSend(emailsForSelectedCompanies);
    }
    if (sendTo === "users") {
      setEmailsToSend(
        confirmedAdminUsers
          .filter((cau) => selectedAdminEmails.includes(cau.Attributes?.find((att) => att.Name === "email")?.Value ?? ""))
          .map((cau) => cau.Attributes?.find((att) => att.Name === "email")?.Value ?? "")
      );
    }
  }, [selectedAdminEmails, selectedCompanyNames]);

  const ADMIN_EMAIL = "admin@strataintel.com";

  return (
    <div>
      <h2 className="mb-6 text-xl font-semibold text-gray-700">Send Email To Leadership</h2>
      <div>
        <div className="flex items-center">
          <label htmlFor="to" className="block text-sm font-medium leading-6 text-gray-900 mr-2">
            Send to:
          </label>
          <select
            id="to"
            value={sendTo}
            className={`block max-w-48 rounded-md border-0 py-1.5 pl-3 pr-10 ${
              sendTo === "" ? "text-gray-500" : "text-gray-900"
            } ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6`}
            onChange={(e) => setSendTo(e.target.value as "all" | "companies" | "users")}
          >
            <option value="" disabled>
              Choose Recipients...
            </option>
            <option value="all">Everyone</option>
            <option value="companies">Select Companies</option>
            <option value="users">Select Users</option>
          </select>
          {(sendTo === "companies" || sendTo === "users") && <Button text="Add All" onClick={addAll} className="ml-2" size="sm" icon={PlusIcon} variant="secondary" />}
        </div>
        <div className="mt-2">
          {sendTo === "companies" && (
            <MultiSelectField
              values={companies.map((c) => c.company_name).filter((cn) => !selectedCompanyNames.includes(cn))}
              selectedValues={selectedCompanyNames}
              setSelectedValues={setSelectedCompanyNames}
              placeholder="Search Companies"
            />
          )}
          {sendTo === "users" && (
            <MultiSelectField
              values={confirmedAdminUsers
                .map((cau) => cau.Attributes?.find((att) => att.Name === "email")?.Value ?? "")
                .filter((email) => !selectedAdminEmails.includes(email))}
              selectedValues={selectedAdminEmails}
              setSelectedValues={setSelectedAdminEmails}
              placeholder="Search Users"
            />
          )}
        </div>
      </div>
      <div className="mt-5 border-t pt-2 rounded">
        <TabGroup>
          <TabList className="group flex items-center">
            <Tab className="rounded-md border border-transparent bg-white px-3 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 data-[selected]:bg-gray-100 data-[selected]:text-gray-900 data-[selected]:hover:bg-gray-200">
              Write
            </Tab>
            <Tab className="ml-2 rounded-md border border-transparent bg-white px-3 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 data-[selected]:bg-gray-100 data-[selected]:text-gray-900 data-[selected]:hover:bg-gray-200">
              Preview
            </Tab>

            <div className="ml-auto hidden items-center space-x-5 group-has-[*:first-child[aria-selected='true']]:flex">
              <div className="flex items-center">
                <label htmlFor="attachments">
                  <PaperClipIcon aria-hidden="true" className="h-5 w-5 cursor-pointer text-gray-600 hover:text-gray-800" />
                  <input
                    id="attachments"
                    type="file"
                    multiple
                    onChange={(e) => {
                      const files = e.target.files;
                      if (files) {
                        setAttachments([...attachments, ...Array.from(files)]);
                      }
                    }}
                    className="sr-only -m-2.5 inline-flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                  ></input>
                </label>
              </div>
            </div>
          </TabList>
          <TabPanels className="mt-1">
            {/* Write */}
            <TabPanel className="-m-0.5 rounded-lg p-0.5">
              <label htmlFor="subject" className="sr-only">
                Subject
              </label>
              <input
                id="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                type="text"
                placeholder="Subject..."
                className="block w-full border-0 text-lg font-medium placeholder:text-gray-400 focus:ring-0"
              />
              <label htmlFor="body" className="sr-only">
                Email Body
              </label>
              <div>
                <textarea
                  id="body"
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                  rows={7}
                  placeholder="Add your email body..."
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </TabPanel>
            {/* Preview */}
            <TabPanel className="-m-0.5 rounded-lg p-0.5">
              <div className="border-b">
                <div className="mx-px mt-px px-3 pt-2 font-semibold leading-5 text-gray-800">{subject}</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: htmlBody,
                  }}
                  className="mx-px mt-px px-3 pb-10 pt-2 text-sm leading-5 text-gray-800" // whitespace-pre-line
                ></div>
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
        {attachments.length > 0 && (
          <div className="flex gap-2 mt-2 items-center">
            <div className="text-sm text-gray-600">Attachments:</div>
            {attachments.map((attachment, index) => (
              <span
                key={index}
                className="inline-flex items-center gap-x-0.5 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
              >
                {attachment.name}
                <button
                  onClick={() => setAttachments(attachments.filter((att) => att !== attachment))}
                  type="button"
                  className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20"
                >
                  <span className="sr-only">Remove</span>
                  <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-gray-600/50 group-hover:stroke-gray-600/75">
                    <path d="M4 4l6 6m0-6l-6 6" />
                  </svg>
                  <span className="absolute -inset-1" />
                </button>
              </span>
            ))}
          </div>
        )}
        <div className="mt-2 flex justify-end items-center">
          <div className="text-sm text-gray-500 mr-3">
            Sending as{" "}
            <span>
              <select
                onChange={(e) => (e.target.value === ADMIN_EMAIL ? setSendAsAdmin(true) : setSendAsAdmin(false))}
                className="rounded py-0 pl-2 pr-7 ml-0 text-gray-500 text-sm ring-gray-400 outline outline-1 -outline-offset-1 outline-gray-300"
              >
                <option>{auth.claims.email}</option>
                <option>{ADMIN_EMAIL}</option>
              </select>
            </span>{" "}
            to {emailsToSend.length} users
          </div>
          <Button text="Send" icon={PaperAirplaneIcon} onClick={sendEmail} submitting={sending} disabled={emailsToSend.length <= 0} />
        </div>
        {error && <div className="flex justify-end text-sm text-red-500 mt-2">{error}</div>}
      </div>
    </div>
  );
}
