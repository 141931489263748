import { useContext, useState } from "react";
import React from "react";
import Button from "./Button";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { AuthContext } from "../context/authContext";
import { useLocation, useNavigate } from "react-router";
import { CompanyAdminUser, Role, Survey } from "../types";
import { BuildingOfficeIcon, PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import useApi from "../hooks/useApi";
import { getUserAttribute } from "../libs/helpers";

export default function AssesmentOverview(props: { survey: Survey; deleteSurvey: () => void }) {
  const { survey, deleteSurvey } = props;
  const { getApiData } = useApi();
  const [editable, setEditable] = useState<boolean>(false);
  const auth = useContext(AuthContext);

  const { data: companyAdminUsers } = useQuery<CompanyAdminUser[]>({
    queryKey: ["companyAdminUsers"],
    queryFn: () => getApiData(`/strata/admins`),
    initialData: [],
  });

  const location = useLocation();
  const navigate = useNavigate();

  const assessmentId = location.pathname.replace("/assessment/", "");

  const companyLeaders = companyAdminUsers.filter((cau) => cau.companyIds.includes(survey.company_id) && cau.leaderScores.length > 0);

  return (
    <div className="bg-white mx-auto rounded-lg border shadow-md p-6 mt-1">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-bold leading-none text-zinc-900">{survey.survey_name}</h3>
      </div>
      {editable ? (
        <div>
          <Formik
            initialValues={{
              name: survey.survey_name,
              statusId: survey.status_id,
              startDate: new Date(survey.start_date).toISOString().split("T")[0],
              endDate: new Date(survey.end_date).toISOString().split("T")[0],
              time: new Date(survey.start_date).toISOString().split("T")[1].split(".")[0],
              all: "",
            }}
            validate={(values) => {
              const errors: any = {};
              if (!values.startDate) {
                errors.startDate = "Start date is required";
              } else if (!values.endDate) {
                errors.endDate = "End date is required";
              } else if (values.startDate > values.endDate) {
                errors.endDate = "End date must be after start date";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              setSubmitting(true);

              values.startDate = new Date(`${values.startDate}T${values.time}Z`).toUTCString();
              values.endDate = new Date(`${values.endDate}T${values.time}Z`).toUTCString();
              try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/strata/surveys/${assessmentId}`, {
                  method: "PATCH",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: auth.sessionInfo?.idToken ? auth.sessionInfo.idToken : "",
                  },
                  body: JSON.stringify(values),
                });

                if(response.ok) {
                  window.location.reload();
                  return
                }

                const body = await response.json();
                alert(body.errors[0])
              } catch (err: any) {
                setErrors({ all: err.message });
              }
            }}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <div className="relative z-0 mb-6 w-full group">
                  <Field
                    type="text"
                    name="name"
                    className="block py-2.5 px-0 w-full text-sm text-zinc-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    htmlFor="name"
                    className="absolute text-sm text-zinc-500 dark:text-zinc-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-sky-600 peer-focus:dark:text-sky-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Assessment Name
                  </label>
                  <ErrorMessage className="text-red-500 text-xs" name="name" component="div" />
                </div>

                <div className="relative z-0 mb-6 w-full group flex gap-3">
                  <div>
                    <Field
                      type="date"
                      name="startDate"
                      className="block py-2.5 px-0 w-full text-sm text-zinc-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="startDate"
                      className="absolute text-sm text-zinc-500 dark:text-zinc-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:text-sky-600 peer-focus:dark:text-sky-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Start Date
                    </label>
                    <ErrorMessage className="text-red-500 text-xs" name="startDate" component="div" />
                  </div>

                  <div>
                    <Field
                      type="date"
                      name="endDate"
                      className="block py-2.5 px-0 w-full text-sm text-zinc-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="endDate"
                      className="absolute text-sm text-zinc-500 dark:text-zinc-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:text-sky-600 peer-focus:dark:text-sky-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      End Date
                    </label>
                    <ErrorMessage className="text-red-500 text-xs" name="endDate" component="div" />
                  </div>

                  <div>
                    <Field
                      type="time"
                      name="time"
                      className="block py-2.5 px-0 w-full text-sm text-zinc-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="time"
                      className="absolute text-sm text-zinc-500 dark:text-zinc-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:text-sky-600 peer-focus:dark:text-sky-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Time
                    </label>
                    <ErrorMessage className="text-red-500 text-xs" name="time" component="div" />
                  </div>
                  <div className="text-gray-700 mt-2">UTC</div>
                </div>
                <div className="text-xs text-zinc-800 -mt-3">
                  UTC timestamp: {values.startDate}T{values.time}Z
                </div>
                <div className="text-xs text-zinc-800 mt-1 mb-4">
                  Your time ({Intl.DateTimeFormat().resolvedOptions().timeZone}): {new Date(`${values.startDate}T${values.time}Z`).toLocaleString()}
                </div>

                <div className="flex justify-between">
                  <Button text="Cancel" variant="secondary" onClick={() => setEditable(false)} icon={XMarkIcon} />
                  <Button text="Edit Assessment" submitting={isSubmitting} type="submit" icon={PencilIcon} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div>
          <div className="flow-root mb-5">
            <ul>
              <li>
                <b>Id:</b> <span>{survey?.survey_id}</span>
              </li>
              <li>
                <b>Name:</b> {survey?.survey_name}
              </li>
              <li>
                <b>Start Date:</b> {new Date(survey.start_date).toLocaleString()}
                <span className="text-xs text-gray-500 ml-1">(UTC: {new Date(survey?.start_date).toISOString()})</span>
              </li>
              <li>
                <b>End Date:</b> {new Date(survey.end_date).toLocaleString()}
                <span className="text-xs text-gray-500 ml-1">(UTC: {new Date(survey?.end_date).toISOString()})</span>
              </li>
              <li>
                <b>Company:</b>{" "}
                <Link to={`/company/${survey.company_id}`} className="underline text-blue-700">
                  {survey.company_name}
                </Link>
              </li>
              <li>
                <b>{companyLeaders.length > 1 ? "Leaders" : "Leader"}:</b>
                {companyLeaders.map((cl) => (
                  <Link key={cl.Username} to={`/leader/${cl.Username}`} className="underline text-blue-700 ml-1">
                    {getUserAttribute(cl, "given_name")} {getUserAttribute(cl, "family_name")} ({Math.max(...cl.leaderScores.map((ls) => ls.leader_score))})
                  </Link>
                ))}
              </li>
            </ul>
          </div>
          {editable ? (
            <div className="flex justify-between">
              <Button text="Cancel" variant="danger" onClick={() => setEditable(false)} />
              <Button text="Submit" variant="success" />
            </div>
          ) : (
            <div className="flex space-x-2">
              <Button text={`Go to Company Details`} size="sm" icon={BuildingOfficeIcon} onClick={() => navigate(`/company/${survey.company_id}`)} />

              {auth.role === Role.Manager && (
                <div className="flex justify-between">
                  <Button text="Edit" size="sm" variant="secondary" onClick={() => setEditable(true)} allowedRoles={[Role.Manager]} icon={PencilIcon} />
                  {/* {survey.status_id === 1 && <Button text="Delete" variant="danger" onClick={() => deleteSurvey()} allowedRoles={[Role.Manager]} />} */}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
